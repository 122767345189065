<template>
	<div class="container">
		<div class="title-wrap">
			<h2 class="title">프리미엄</h2>
		</div>
  <template v-if="bid_first_load">
  <el-card class="box-card">
  <div slot="header" class="clearfix">
    <span>안내</span>
  </div>
  <el-col :span="12">
    <b>입찰 기간</b><br/>{{dateFormat(bid_session.bid_start)}} ~ {{dateFormat(bid_session.bid_end)}}<br/>({{"최종 마감: " + dateFormat(bid_session.bid_final)}})<br/><br/>
    <b>게시 기간</b><br/> {{dateFormat(bid_session.post_start)}} ~ {{dateFormat(bid_session.post_end)}}<br/><br/>
    <b>결제 기한</b><br/> {{dateFormat(bid_session.payment_due)}} 까지
    <br/><br/>
    <hr style="width: 95%;" />
    <br/>
    <b>입찰 금액 단위</b><br/> 5,000원<br/><br/>
    <b>입찰 최소 금액</b><br/> 10,000원<br/><br/>
  </el-col>
  <el-col :span="12">
    <p><strong>입찰 안내</strong></p>
<p>1. 표시된 기간동안 입찰을 통해 경매에 참여하실 수 있습니다.<br />2. 표시된 기간이 종료되기 5분 전, 상위 입찰한 2개 서버가 변동되는 추가 입찰이 있는경우 입찰 기간이 2분씩 연장됩니다. (최종 마감 시각까지 연장될 수 있습니다.)<br />3. 표시된 기간이 종료되면 상위 입찰하신 2개의 서버가 낙찰됩니다.<br /><p style='color: red;'><b>4. 입찰 후 취소 또는 금액 수정은 어떠한 경우에도 불가능합니다.</b></p></p>
<br/>
<p><strong>결제 안내</strong></p>
<p>1. 경매가 종료된 후 표시된 결제 기한 이전에 결제를 완료해주셔야 합니다.<br />2. 결제는 농협 3020794730011 송기현 으로, 낙찰된 서버 이름으로 입금해주세요. 입찰하신 금액은 부가가치세가 포함된 금액입니다.<br />3. 낙찰 후 입찰 금액이 결제되지 않을 경우, 마인페이지 이용에 불이익이 발생할 수 있습니다.<br />4. 낙찰 후 현금영수증 발행을 위해 이메일로 연락을 드릴 예정입니다.</p>
<br/>
<p><strong>프리미엄 서비스 이용약관</strong></p>
<p>1. 프리미엄 게시 기간 중 서비스 장애로 인해 부가서비스 이용에 지장이 있었을 경우, 장애가 발생한 기간 만큼 이용기간을 연장해드립니다.<br />- 단 아래와 같은 면책 사항에 대해서는 보상을 해드리지 않습니다.<br />ㄱ. 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우<br />ㄴ. 서비스용 설비의 보수, 교체, 정기점검, 공사 등 기타 이에 준하는 사유<br />ㄷ. 회원의 고의 또는 과실로 인한 서비스 이용의 장애 (단, 회원의 부득이 하거나 정당한 사유가 있는 경우에는 제외)</p>
<p>2. 프리미엄 서비스를 이용한다고 하더라도 마인페이지는 서버의 흥행 또는 성공을 보장하지 않습니다.</p>
<p>3. 낙찰 후 입찰 금액이 결제되지 않을 경우, 마인페이지 이용에 불이익이 발생할 수 있습니다.</p>
<br/>
  </el-col>
  </el-card>
  <br/>
  <el-row>
  <el-col :span="12">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>입찰 내역</span>
      </div>
      <el-table
      :data="bids"
      :row-class-name="tableRowClassName"
      style="width: 100%">
        <el-table-column
          prop="rank"
          label="순위"
          width="80">
        </el-table-column>
        <el-table-column
          prop="server"
          label="서버"
          width="150">
        </el-table-column>
        <el-table-column
          prop="price"
          width="140"
          label="금액">
        </el-table-column>
        <el-table-column
          prop="time"
          label="마지막 입찰 시각">
        </el-table-column>
      </el-table>
      <br/>
    </el-card>
    <br/>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>내 입찰 내역</span>
      </div>
      <el-table
      :data="mybids"
      style="width: 100%">
        <el-table-column
          prop="rank"
          label="순위"
          width="80">
        </el-table-column>
        <el-table-column
          prop="server"
          label="서버"
          width="150">
        </el-table-column>
        <el-table-column
          prop="price"
          width="140"
          label="금액">
        </el-table-column>
        <el-table-column
          prop="time"
          label="마지막 입찰 시각">
        </el-table-column>
      </el-table>
      <br/>
    </el-card>
  </el-col>
  <el-col :span="12">
    <el-card class="box-card">

      <div slot="header" class="clearfix">
        <span>입찰 진행</span>
      </div>
      <el-form @submit.prevent="bid">
      <el-select v-model="server" placeholder="서버를 선택하세요" style="width: 100%">
        <el-option
          v-for="myserver in myservers"
          :key="myserver.idx"
          :label="myserver.name"
          :disabled="!bid_load"
          :value="myserver.idx">
        </el-option>
      </el-select>
      <el-input
        @keyup.enter.native="bid"
        placeholder="금액"
        v-model="price"
        :disabled="!bid_load"
        clearable>
      </el-input>
			<el-checkbox v-model="termsAgree" :disabled="!bid_load"> 이용약관 및 개인정보취급방침의 모든 내용에 동의합니다.</el-checkbox><br/><br/>
      <p class="error">{{ bid_error }}</p>
      <el-button style="float: right;" type="success" :disabled="!bid_load" @click="bid">입찰</el-button>
      </el-form>
      <br/><br/><br/>


    </el-card>
  </el-col>

</el-row>
  </template>
 
	</div>
</template>

<script>
import axios from "axios";
import { apiUrl } from '../constants/config';
import { Loading } from 'element-ui';

var loadingInstance;

export default {
	data() {
		return {
      termsAgree: false,
      bid_error: "",
      bid_first_load: false,
      bid_load: false,
      bid_session: {},
      server: null,
      myservers: [

      ],
      price: null,
			bids:  [
      ],
      mybids: [],

		}
	},
  methods: {
    server_parse(idx){
      for(var i=0; i<this.myservers.length; i++){
        if(this.myservers[i].idx == idx)
          return this.myservers[i].name;
      }
    },
       loadPremium(option){
      if(option == 0)
      {
      loadingInstance = Loading.service({ fullscreen: true });
      this.bid_load = false;
      }
      var vm = this;
      axios.get(apiUrl+'/premium/info', { withCredentials: true })
      .then(function(response) {
        if(response.data.success){
          vm.bid_first_load = true;
          vm.myservers = response.data.myservers;
          vm.bids = response.data.bids;
          vm.mybids = response.data.mybids;

          for(var i=0; i<vm.bids.length; i++){
            const result = vm.bids[i].price.toString().replace(/\D/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            vm.bids[i].price = result + "원";
          }
          for(var i=0; i<vm.mybids.length; i++){
            const result = vm.mybids[i].price.toString().replace(/\D/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            vm.mybids[i].price = result + "원";
          }
          vm.bid_session = response.data.bid_session;
          if(option==0)
            vm.bid_load = true;

          if(vm.mybids.length && option==0){
            vm.price = vm.mybids[0].price;
            vm.server = vm.mybids[0].idx;
          }
          if(option==0)
            loadingInstance.close();
        }
        else{
          if(option==0)
            loadingInstance.close();
            clearInterval(vm.interval);
          vm.$swal.fire(
              '프리미엄 경매에 참여하실 수 없습니다.',
              response.data.message,
              'warning'
            ).then(() => {
              vm.$router.push(response.data.redirect);
            })
        }
      })
      .catch(function(error) {
        console.log(error);
      });

    },
    bid(){
      var vm = this;
      vm.bid_load = false;
      vm.bid_error = "";

      if(this.termsAgree == false)
      {
        vm.bid_load = true;
        vm.bid_error = "이용약관 및 개인정보취급방침의 모든 내용에 동의해주세요.";
        return;
      }

      if(this.server == null)
      {
        vm.bid_load = true;
        vm.bid_error = "입찰할 서버를 선택하세요.";
        return;
      }

      if(this.price == null)
      {
        vm.bid_load = true;
        vm.bid_error = "입찰할 가격을 입력하세요.";
        return;
      }

      if(this.price.replace(/,/g, "") % 5000 != 0)
      {
        vm.bid_load = true;
        vm.bid_error = "입찰할 가격은 5,000원 단위여야 합니다.";

        return;
      }

      if(this.price.replace(/,/g, "") < 10000)
      {
        vm.bid_load = true;
        vm.bid_error = "입찰 최소 금액은 10,000원 입니다.";

        return;
      }


      vm.$swal.fire({
					title: '정말 입찰하시겠습니까?',
					html: "서버: "+this.server_parse(this.server) + "<br/>금액: "+this.price+"원<br/><br/><p style='color: red;'><b>입찰 후 취소 또는 금액 수정은 어떠한 경우에도 불가능합니다.</b></p>",
					icon: 'warning',
					showDenyButton: true,
					showCancelButton: false,
					confirmButtonText: '입찰하기',
					denyButtonText: `닫기`,
					}).then((result) => {
					if (result.isConfirmed) {

          loadingInstance = Loading.service({ fullscreen: true });

						
     axios.post(apiUrl+'/premium/bid', {
        session: vm.bid_session.idx,
        server: vm.server,
        price: vm.price
      }, { withCredentials: true })
      .then(function(response) {
        if(response.data.success){
          vm.bid_load = true;
          loadingInstance.close();
          vm.loadPremium();
          vm.$swal.fire(
              '성공적으로 입찰하였습니다.',
              "입찰이 등록되었습니다.",
              'success'
          )
        }
        else{
          vm.bid_error = response.data.message;
          vm.bid_load = true;
          loadingInstance.close();
        }
      })
      .catch(function(error) {
        console.log(error);
      });



					}
          else {
            vm.bid_load = true;
          }
				})

 
  
    },

    tableRowClassName({row, rowIndex}) {
        if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 0) {
          return 'success-row';
        }
        return '';
    },
    dateFormat(date) {
        var date = new Date(date);
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let minute = date.getMinutes();
        let second = date.getSeconds();

        month = month >= 10 ? month : '0' + month;
        day = day >= 10 ? day : '0' + day;
        hour = hour >= 10 ? hour : '0' + hour;
        minute = minute >= 10 ? minute : '0' + minute;
        second = second >= 10 ? second : '0' + second;

        return date.getFullYear() + '년 ' + month + '월 ' + day + '일 ' + hour + '시 ' + minute + '분 ' + second + "초";
    },
 

  },
	mounted(){

    this.loadPremium(0);
		this.interval = setInterval(function () {
                this.loadPremium(1);
    }.bind(this), 1000);
	},

  beforeMount(){

      
  },
  destroyed() {
    clearInterval(this.interval);
  },
  watch: {
    price: function(newValue) {
      const result = newValue.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.price = result;
    }
  },
  computed: {
 

  }
}
</script>

<style>

 .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }


.box-card {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.error {
	color: red;
	font-size: 14px;
}
</style>
